@import 'scss/default';

.collect-graphics-preview-block {
    display: flex;
    border-radius: 6px;
    background-color: var(--secondary-bg-color);
    overflow: hidden;
    padding: 5px;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    &.open {
        color: white;
    }

    &.done {
        color: var(--success-color);
    }

    .link-button-ghost {
        max-width: 100% !important;
    }

    .status-wrap {
        background-color: var(--primary-bg-color);
        border-radius: 6px;
        transition: all var(--animation-speed) ease-in-out;
        position: relative;
        display: flex;
        flex-direction: column;

        &:hover {
            background-color: rgba(var(--primary-bg-color-rgb), var(--opacity70));
        }

        button {
            background-color: transparent !important;
            border: none;
            cursor: pointer;
            padding: 6px 5px 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 50px;
            width: 55px;
            align-items: center;

            .anticon {
                font-size: 18px;
                line-height: 1px;
                display: block;
                margin-bottom: 4px;
            }

            .status {
                font-size: 9px;
                line-height: 1.1em;
                margin: 0;
            }
        }
    }

    .action {
        display: flex;
        justify-content: space-between;

        a,
        button {
            background-color: transparent !important;
            min-width: 80px !important;
            max-width: 90px;
            padding: 0 15px;

            .anticon {
                font-size: 18px;
                margin-right: 5px;
            }

            & + * {
                margin-left: 5px;
            }
        }
    }
}

.collect-graphics-info-block {
    margin-top: 5px;
    margin-bottom: 7px;

    .info-item {
        margin-right: 15px;
        display: inline-block;

        .label {
            color: var(--secondary-text-color);
            margin-right: 5px;
        }

        .value {
            font-weight: bold;
        }
    }
}

.info.grid-three-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
    list-style: none;
    padding: 0;

    li {
        text-align: center;

        h5 {
            font-size: 12px;
            margin-bottom: 2px;
            color: var(--secondary-text-color);
        }

        p {
            font-weight: bold;
            margin: 0;
        }
    }
}