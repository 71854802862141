// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  white-space: nowrap;
  text-align: center;

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 0;
    min-height: $va-height;
  }

  > * {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
    max-width: 99%;
  }
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto ) {
  display: table;
  .#{$child-name1}{
    display: table-cell;
    vertical-align: $valign;
    width: $width1;
  }
 .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign;
    width: $width2;
  }
}


// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value:on){
  @if $value == on{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
  }
  @else{
    -webkit-font-smoothing:subpixel-antialiased;
    -moz-osx-font-smoothing:auto;
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

/// Animate css properties
// usage
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: .3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list:();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: append($list, $str, comma);
  }
  transition: $list;
}


/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &::-moz-placeholder {opacity: 1; @content}
  &:-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
  &.placeholder {@content}
}


@mixin bg-radial-gradient($color: var(--primary-color)){
  background: rgba($color, 1);
  background: radial-gradient(
                  circle,
                  rgba($color, 0.7) 0%,
                  rgba($color, 1)  70%,
                  rgba($color, 1)  100%
  );
}

@mixin button-overlay($color: rgba(0, 0, 0, 0.1), $offset : -1px){
    content: "";
    left: $offset;
    right: $offset;
    top: $offset;
    bottom: $offset;
    display: block;
    position: absolute;
    background-color: $color;
    opacity: 0;
}

@mixin bg-image($image, $color : transparent, $size: cover) {
  background: url($image) no-repeat scroll center center $color;
  background-size: $size;
}

@mixin ant-input-hover {
  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px;
  }
}

@mixin ant-btn-default-disabled {
  background-color: hsla(0,0%,100%,.15)!important;
  background-color: rgba(var(--secondary-bg-color-rgb),var(--opacity15))!important;
  color: #222b33!important;
  color: var(--secondary-text-color)!important
}