.craft-change-status-btn {
    &.collect-graphics-type {
        &.open {
            .anticon {
                color: white;
            }
        }

        &.done {
            .anticon {
                color: var(--success-color);
            }
        }
    }

    &.status:not(.collect-graphics-type) {
        .anticon {
            color: inherit;
        }
    }

    .anticon {
        margin-right: 5px;
    }
}

.collect-graphics-icon {
    color: white;

    .open & {
        color: white !important;
    }

    .done & {
        color: var(--success-color) !important;
    }
}
